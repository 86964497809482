@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    scroll-behavior: smooth;
  }

  body {
    font-family: 'Inter', sans-serif;
    @apply text-gray-800 dark:text-gray-200 antialiased;
  }

  h1, h2, h3, h4, h5, h6 {
    @apply font-semibold tracking-tight;
  }
}

@layer components {
  .btn {
    @apply px-4 py-2 rounded-lg transition-colors duration-200;
  }

  .btn-primary {
    @apply bg-primary text-white hover:bg-primary/90;
  }

  .btn-secondary {
    @apply bg-secondary text-white hover:bg-secondary/90;
  }

  .card {
    @apply bg-white dark:bg-gray-800 rounded-lg shadow-md p-4;
  }
}

@layer utilities {
  .scrollbar-hide::-webkit-scrollbar {
    display: none;
  }

  .text-shadow {
    text-shadow: 0 2px 4px rgba(0,0,0,0.1);
  }
}